import * as React from "react";
const SwimmingPool = () => (
  <div className="flex flex-wrap overflow-hidden">
    <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-1">
      <img
        className="w-full object-cover"
        src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654841165/training%20center/swimming%20pool/swimming-pool-elite_iunyqg.webp"
        alt="hua hin muay thai team"
      />
    </div>

    <div className="bg-geraniumlake w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 p-8 lg:p-16 order-2 ">
      <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold pt-12 text-white">
        Swimming Pool
      </h2>
      <h3 className="text-xl md:text-2xl lg:text-3xl font-light py-4 text-white">
        Rooftop Pool at your disposal
      </h3>
      <p className="text-xl md:text-3xl lg:text-xl py-6 text-white leading-loose md:leading-loose lg:leading-loose uppercase lg:pr-20">
        Enjoy our 30 metres long rooftop pool surrounded by a small garden oasis
        with lounge beds &amp; lounge music. Escape from Bangkok’s congestion in
        a relaxed, cozy atmosphere.
      </p>
    </div>
    <div className="bg-white pt-16 md:pt-20 md:pb-16 p-8 lg:p-20 w-full overflow-hidden  lg:w-1/2 xl:w-1/2 order-4 lg:order-3">
      <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold pt-12 text-geraniumlake">
        Swimming
      </h2>
      <h3 className="text-xl md:text-2xl lg:text-3xl font-light py-4 text-black">
        For endurance and health
      </h3>
      <p className="text-xl md:text-3xl lg:text-xl py-2 text-black leading-loose md:leading-loose lg:leading-loose uppercase">
        Swimming is a great workout because you need to move your whole body
        against the resistance of the water. <br />​ It is a good all-round
        activity because it:
      </p>
      <ul className="list-disc text-xl md:text-3xl lg:text-xl py-2 text-black leading-loose px-12">
        <li className="py-2">
          Keeps your heart rate up but takes some of the impact stress off your
          body. ​
        </li>
        <li className="py-2">
          Builds endurance, overall muscle strength in your core and improves
          cardiovascular fitness.
        </li>
        <li className="py-2">
          Helps maintain a healthy weight and enduring heart and lungs.
        </li>
        <li className="py-2">Tones muscles and builds strength.</li>
        <li className="py-2">
          Provides an all-over body workout, as nearly all of your muscles are
          used during swimming.
        </li>
      </ul>
    </div>
    <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-3 lg:order-4">
      <img
        className="w-full object-cover"
        src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654841165/training%20center/swimming%20pool/by-the-pool-elite_un3xwf.webp"
        alt="exercises gym training bangkok muay thai team"
      />
    </div>
  </div>
);

export default SwimmingPool;
